/***breadcrumb_cus***/

.breadcrumb_cus {
    margin-bottom: 20px;
}

.breadcrumb_cus ul {
    margin: 0px;
    padding: 0px;
}

.breadcrumb_cus ul li {
    display: inline-block;
    vertical-align: middle;
}

/*.breadcrumb_cus ul li a {}*/

.breadcrumb_cus ul li a {
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px 0 0;
    padding: 0 6px 0px 0;
    border-right: 1px solid #9a9a9a;
    text-decoration: none;
}

.breadcrumb_cus ul li:last-child a {
    border-right: 0px solid #9a9a9a;
}

.page_title h3 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0 0 0;
}

/***EnD breadcrumb_cus***/

/* The container */

.cus_radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 26px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */

.cus_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #bfc7d7;
}

/* On mouse-over, add a grey background color */

/*.cus_radio:hover input~.checkmark {
    //    background-color: #ccc;
}*/

/* When the radio button is checked, add a blue background */

.cus_radio input:checked~.checkmark {
    background-color: #FFF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.cus_radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.cus_radio .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #c2cad9;
}

/* meal_select_list */

.meal_list_header_container {
    padding-left: 21px;
}

.meal_select_list {
    margin: 52px 0 82px 0;
}

.meal_list {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 3px 16px 0px 16px;
    margin: 0 0 12px 0;
    border: 1px solid transparent;
}

.meal_list .meal_per_week_down, .meal_list .meal_per_week_rate, .meal_list .meal_per_week, .meal_list .cus_radio {
    display: inline-block;
    vertical-align: middle;
}

.meal_per_week_down {
    background: #000;
    /* display: inline-block; */
    /* vertical-align: middle; */
    border-radius: 100px;
    padding: 7px 20px;
    color: #FFF;
    font-weight: 300;
    font-size: 14px;
    margin: 27px 0 0 0;
    /* position: relative; */
    /* left: -682px; */
    cursor: pointer;
    line-height: 18px;
}
.meal_per_week_down i{position: relative;top: 1px;}

.meal_per_week {
    margin: 7px 0 0 12px;
}

.meal_per_week_number {
    color: #141414;
    background: #FFF;
    padding: 5px;
    border-radius: 100%;
    font-size: 50px;
    display: inline-block;
    width: 88px;
    height: 89px;
    font-weight: 600;
    text-align: center;
    line-height: 68px;
    border: 8px solid #f1f1f1;
    position: relative;
    margin: -9px 0 0 0;
    vertical-align: top;
}

.meal_per_week_title {
    background: #FFF;
    text-align: center;
    font-size: 20px;
    color: #141414;
    height: 70px;
    display: inline-block;
    width: 240px;
    margin: 0px 0 0 -21px;
    vertical-align: top;
    line-height: 70px;
    border-radius: 0 6px 6px 0;
}

.meal_per_week_rate {
    font-size: 18px;
    font-weight: 400;
    margin-left: 33px;
}

.meal_per_week_rate span {
    display: inline-block;
    vertical-align: top;
}

.meal_per_week_rate span span {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
}

.meal_list_open {
    border-top: 1px solid #c2c2c2;
    padding: 15px 0px 15px 18px;
}

.meal_list_open_header {
    margin: 0px 0px 15px 0px;
}

.meal_list_open_header_bottom {
    margin: 15px 0px 0px 0px;
}

.subs_select, .selected_number, .continue_btn {
    display: inline-block;
    vertical-align: middle;
}

.selected_number {
    font-size: 16px;
    color: #534556;
    margin: 0 15px 0 0;
}

.continue_btn a {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 7px 40px;
    font-size: 15px;
    background: #000;
    color: #FFF !important;
    text-decoration: none !important;
    font-weight: 300;
    border-radius: 100px;
}

.meal_list_img, .meal_list_ctg, .meal_list_qnt {
    display: inline-block;
    vertical-align: middle;
}

.meal_list_img {
    margin: 5px 15px 5px 10px;
    border: 1px solid #efefef;
}

.meal_list_ctg h3 {
    color: #141414;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
}

.sub_title {
    color: #141414;
    font-size: 13px;
    font-weight: 300;
    margin: 0;
    font-style: italic;
}

.sub_title p {
    margin: 0px;
    padding: 0px;
}

.meal_list_qnt {
    /* width: 262px; */
    text-align: right;
}

.meal_list_qnt span {
    width: 22px;
    height: 22px;
    border: 1px solid #b5b5b5;
    background: #f1f1f1;
    display: inline-block;
    margin: 4px;
    border-radius: 100%;
    vertical-align: top;
    cursor: pointer;
}

.meal_list_qnt span.active {
    border: 1px solid #000;
    background: #000;
}

.meal_list_content {
    background: #FFF;
    padding: 0 15px 0 0;
    border-bottom: 2px solid #f1f1f1;
    position: relative;
}

.meal_list_wrapper {
    max-height: 640px;
    overflow-y: scroll;
}

.meal_list_active {
    border: 1px solid #bfc7d7;
}

.outer-container {
    /* position: absolute; */
    display: inline-block;
    width: 300px;
    /* height: 78px; */
    /* border: 1px solid #ccc; */
    /* top: 0px; */
    /* right: 0px; */
    vertical-align: middle;
}

.inner-container {
    vertical-align: middle;
    text-align: right;
    padding-right: 10px;
}

.centered-content {
    display: inline-block;
    text-align: right;
    background: #fff;
}

/* End meal_select_list */

/** package **/

.packege__wrapper {
    margin: 0 0 110px 0;
}

.package_ctg {
    width: 288px;
    text-align: center;
    background: #f1f1f1;
    border-radius: 15px;
    padding: 25px 25px 12px 25px;
    display: inline-block;
    vertical-align: bottom;
}

.package_title {
    font-size: 18px;
    margin-bottom: 28px;
}

.package_meal_qnt {
    background: #FFF;
    height: 208px;
    width: 208px;
    text-align: center;
    font-size: 150px;
    display: block;
    border-radius: 50%;
    font-weight: bold;
    line-height: 180px;
    margin: -7px auto 0px auto;
    border: 10px solid #f1f1f1;
    position: relative;
}

.package_meal_qnt_per_week {
    background: #FFF;
    margin: -97px 0 15px 0;
    border-radius: 15px;
    height: 191px;
}

.package_ctg_per_week {
    display: block;
    top: 110px;
    position: relative;
    font-weight: 600;
    font-size: 16px;
}

.package_ctg_per_week_rate {
    display: block;
    top: 116px;
    position: relative;
    font-weight: 500;
    font-size: 22px;
}

.package_ctg_select, .package_ctg_select:hover {
    display: inline-block;
    vertical-align: top;
    padding: 16px 30px;
    background: #141414;
    font-weight: 600;
    font-size: 18px;
    margin: 17px 0 0 0px;
    width: 100%;
    border-radius: 47px;
    color: #FFF;
    text-transform: uppercase;
    text-decoration: none;
}

.package_ctg_popular {
    border: 1px solid #a4a4a4;
}

.package_ctg_popular_title {
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 2px solid #000;
    padding: 0px 0 3px 0;
    width: 150px;
    text-align: center;
    margin: 0px auto 30px auto;
}

.packege_title {
    font-size: 28px;
    margin: 22px 0 60px 0;
    font-weight: 600;
    text-align: center;
}

/** package **/

/** select meal **/

.selected_meal {
    width: 360px;
    float: left;
    background: #f5f5f5;
    padding: 15px;
    border-radius: 6px;
}

.selected_meal_title {
    font-weight: 500;
    color: #534556;
    font-size: 16px;
    margin: 4px 0 10px 0;
}

.selected_meal_title span {
    display: inline-block;
    margin: 0 3px;
}

.selected_meal ul li {
    background-color: #FFF;
    position: relative;
    margin-bottom: 12px;
}

.selected_meal_img {
    display: inline-block;
    width: 78px;
    height: 78px;
    overflow: hidden;
    vertical-align: top;
    margin-right: 10px;
}

.selected_meal_img img {
    width: 78px;
    height: 78px;
}

.selected_meal_short_info {
    display: inline-block;
    vertical-align: top;
    width: 230px;
    margin: 8px 0 0 0;
}

.selected_meal_short_info span {
    font-weight: 600;
    display: block;
    color: #141414;
    font-size: 16px;
}

.selected_meal_short_info h3 {
    font-weight: 600;
    display: block;
    color: #141414;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
}

.selected_meal_short_info h4 {
    font-weight: normal;
    display: block;
    color: #141414;
    font-size: 15px;
    margin: 0px;
    padding: 0px;
    font-style: italic;
}

.selected_meal_remove {
    display: inline-block;
    position: absolute;
    right: 4px;
    top: -4px;
    color: #b9b9b9;
    font-size: 20px;
    font-weight: 100;
    cursor: pointer;
}

.add_1_meal_to_continue {
    margin: 32px 0 20px 0;
    text-align: center;
    color: #333a40;
    font-weight: 500;
    font-size: 14px;
}

.add_1_meal_to_continue_btn {
    text-align: center;
}

.add_1_meal_to_continue_btn a {
    background: #9d9d9d;
    border-radius: 30px;
    color: #787878;
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    text-decoration: none;
}

.add_1_meal_to_continue_btn a.active {
    background: #000;
    color: #FFF;
}

.selected_meal_container {
    float: right;
    width: 800px;
    margin-left: 0;
}

.selected_meal_thumb_img {
    width: 260px;
    height: 234px;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
}

.selected_meal_container ul li {
    text-align: center;
    width: 257px;
    background-image: url(../images/meal_list_bg.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 0 0 25px 0;
    display: inline-block;
    margin: 0 8px 0 0;
}

.selected_meal_container ul li:nth-child(3n+3) {
    margin: 0px;
}

.selected_meal_thumb_img {
    width: 257px;
    height: 234px;
    border-radius: 6px 6px 0 0;
}

.selected_meal_thumb_img img {
    width: 257px;
    height: 234px;
    border-radius: 6px 6px 0 0;
}

.selected_meal_thumb_title {
    font-weight: 600;
    display: block;
    color: #141414;
    font-size: 16px;
    margin: 0;
    padding: 15px 0px 0px 0px;
    cursor: pointer;
}

.selected_meal_thumb_title span {
    display: block;
    font-weight: normal;
    color: #141414;
    font-size: 16px;
    margin: 3px 0px 8px 0px;
    padding: 0px;
    font-style: italic;
}

.selected_meal_container_short_details {
    background: #f1f1f1;
    padding: 0 0 10px 0;
}

.meal_list_qnt.selected_meal_thumb_title_qnt {
    text-align: center;
    width: 100%;
}

.meal_list_qnt.selected_meal_thumb_title_qnt span {
    margin: 4px 2px;
}

/*.custom-modal-header {}*/

.modal_product_view_img {
    float: left;
    width: 600px;
    overflow: hidden;
    margin-right: 15px;
}

.modal_product_view_img img {
    width: 100%;
}

.modal_product_view_desc {
    width: 342px;
    float: left;
}

.product-view-modal-dialog {
    max-width: 1000px;
    margin: 1.75rem auto;
}

.nutritional-facts__summary {
    text-align: center;
}

.nutritional-facts__summary>li {
    color: #ef3742;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    /* flex: 0 0 33.33333333%; */
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    display: block;
    margin: 0px 0 5px 0;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    border-bottom: 1px solid #efefef;
    padding: 0px 0 5px 0;
}

.nutritional-facts {
    margin-bottom: 20px;
}

.nutritional-facts__summary>li>label {
    color: #3f3042;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    width: 82px;
    margin: 0px;
}

.modal_product_view_desc h3 {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 25px 0;
}

.modal_close {
    position: absolute;
    right: 6px;
    top: 3px;
    z-index: 99;
}

.meals_sub_title {
    font-family: 'Montserrat', sans-serif !important;
    margin: 0px 0px 10px 0px !important;
    font-size: 21px !important;
    font-weight: 500 !important;
    /*padding-top: 8px !important;*/
    color: #3e3e3e !important;
    padding: 0 !important;
}

/** mob select meal **/

.mob_show_all_meal {
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    background: #FFF;
    border-top: 1px solid #3f3042;
}

.mob_show_select_meal_list {
    display: none;
}

/** //EnD mob select meal **/

/** //end select meal **/

.meal-thumb-img {
    width: 74px;
    height: 78px;
}

a.reset-rating {
    cursor: pointer;
    padding: 5px;
    text-align: center;
    font-size: 10px;
    color: #f00 !important;
    /* position: absolute; */
    left: 0;
}

.meal-disble {
    background-color: #c2c2c2 !important;
    cursor: not-allowed !important;
}

.meal-item-disble {
    cursor: not-allowed !important;
}

.mealContainer {
    margin: 0px auto 30px auto;
    width: 100%;
}

.meal_list_ctg {
    width: 400px;
}

.meal_list_open_header {
    width: 100%;
}

.meal_list_open_header .subs_select {
    width: 709px;
}

.meal_list_open_header select, .meal_search_box {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    height: 33px;
    padding: 0 10px;
    margin: 0 15px 0 0;
    border-radius: 0;
    border: 1px solid #ced4da;
    font-size: 14px;
}

.changePremiumLabel {
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
    cursor: pointer;
}

.meal_list_open_header .form-group {
    margin: 0px;
}

/*.meal_premium {
    // margin: 10px;
}*/


/**=================== Add New Meals style Custom Radio box=====================**/
.selected_meal-radio-block{
    padding: 8px 0 0 0;
    text-align: left;
    margin: 0;
}
.selected_meal-radio-block .selected_meal-radio {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    margin: 0;
    cursor: pointer;
    font-size: 14px;
    color: #141414;
    font-weight: 400;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* width: auto; */
    width: 200px;
    vertical-align: middle;

  }
  .selected_meal-radio-block .selected_meal-radio small {
	font-weight: 400;
	font-style: italic;
	font-size: 14px;
	position: relative;
	line-height: 20px;
	display: block;
	text-align: left;
}
  .selected_meal-radio-block .selected_meal-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .selected_meal-radio-block .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f1f1f1;
    border-radius: 50%;
    border:2px solid #141414;
  }
  
  .selected_meal-radio:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }
  
  .selected_meal-radio input:checked ~ .checkmark {
    background-color: #f1f1f1;
  }
  
  .selected_meal-radio-block .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .selected_meal-radio input:checked ~ .checkmark:after {
    display: block;
  }
  
  .selected_meal-radio-block .selected_meal-radio .checkmark:after {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #141414;
  }
  .meal_list_content-block{
      display: flex;
      align-items: center;
  }
  .meal-category-type-header {
    font-weight: 700;
    font-size: 16px;
    color:#141414;
    text-transform: capitalize;
    margin: 15px 0 15px 0;
}



/**=================== Add New Meals style=====================**/

.selected_meal_container-inner ul {
    margin-bottom: 10px;
}

.selected_meal-protein {
    margin-bottom: 20px;
    /* padding: 0 8px 0 15px; */
    padding: 0 8px 0 27px;
    text-align: left;
}

.selected_meal-protein ul li {
    background: none;
    width: 20%;
    display: inline-block;
    text-align: left;
    padding: 0;
    margin: 0;
}

.selected_meal-protein ul li strong {
    display: block;
    /* font-size: 12px; */
    font-size: 11px;
    color: #141414;
    font-weight: 600;
    font-style: italic;
    line-height: 15px;
}

.selected_meal-protein ul li span {
    display: block;
    /* font-size: 14px; */
    font-size: 11px;
    color: #141414;
    font-weight: 300;
    font-style: italic;
}

.meal-category-type-header {
    font-weight: 700;
    font-size: 16px;
    color: #141414;
    text-transform: capitalize;
    margin-bottom: 15px;
}

/*.meal_list_content-block{
    // border: 1px solid #f00;
}*/
.meal_list_content-block .meal_list_ctg{
    width: 100%;
    padding: 12px 0 0 0;
}
.selected_meal-radio-block{
    position: relative;
}

/*.meal_list_content-block .selected_meal-protein {
    // width: 250px;
    //margin-bottom: 20px;
    //padding: 3px 2px 3px 8px;
    //text-align: left;
    //background-color: #f7f7f7;
    //margin-top: 4px;
    //margin-left: 24px;
    //display: inline-block;
}*/
.meal_list_content-block .selected_meal-protein {
    width: 225px;
    padding: 3px 2px 3px 8px;
    text-align: left;
    background-color: #f7f7f7;
    margin:0 0 0 24px ; 
    display: inline-block;
    vertical-align: middle;

}
.selected_meal-radio-block-box{
     position: relative;
     /* overflow: hidden; */
     margin: 0 0 15px 0;
}
.selected_meal-radio-block-box .selected_meal-protein ul{
 margin-top: 5px;
}
.meal_list_content-block .montserrat .montserrat.sub_title{
    margin-left: 10px;
}
.selected_meal-radio-block-box-left{
    display: inline-block;
    width: 48%;
    vertical-align: middle;
    
}
.selected_meal-radio-block-box-right{
    display: inline-block;
    width: 51.5%;
    vertical-align: middle;
}
/*.meal_list_content-block .inner-container {
    /* position: absolute;
    top: -2px;
    display: flex;
    width: 410px;
    right: 0;
    text-align: right;
    display: inline-block;
    }*/

    .meal_list_qnt-options{
        display: inline-block;
    }

    .centered-content {
        display: flex;
        text-align: right;
        justify-content: flex-end;
    }
    .meal_list_qnt-options-box_clear {
        width: 22px;
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
    .meal_list_qnt-options-box {
        max-width: 362px;
        display: flex;
        justify-content: flex-end;
    }
    a.reset-rating {
        left: auto;
    }

span.meal_compared_price {
    color: #000;
    position: relative;
    display: initial;
}

span.meal_compared_price::after {
    position: absolute;
    width: 100%;
    top: 12px;
    left: 0;
    height: 2px;
    background-color: red;
    content: "";
    display: block;
    text-align: center;
}

/** 21 **/
/* @media screen and (min-width: 1400px) {
    .mobile-cart-hide {
        float: left;
        width: 360px;
    }
    .meal-shipping-shedule{
        margin: 2vw 0 0 0;
        border-style: solid;
        border-color: #000000;
        border-width: 2px;
        padding: 0px 4px 0px 4px;
    }
    .meal-shipping-shedule .title {
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule .sub-title{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .meal-shipping-shedule-order-day .order-before,
    .meal-shipping-shedule-order-day .arraival-date{
        width: 47%;
        text-align: center;
    }
    .meal-shipping-shedule-order-day .order-before h3, .meal-shipping-shedule-order-day .arraival-date h3 {
        font-size: 12px;
        border-color: #000000;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day .order-before ul li,
    .meal-shipping-shedule-order-day .arraival-date ul li{
        font-size: 12px;
         font-weight: 600;
    }
    .meal-shipping-shedule .note {
        font-size: 11px;
        color: #000;
        font-weight: 600;
    }
    .cart-shedule-table{
       margin: 0 auto;
    width: 44%;
    }
    .cart-shedule-table .meal-shipping-shedule{
       margin: 0 0 1vw 0;
    }
    .cart-shedule-table .title h3{
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 30px 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table .title h4{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
  
    .checkout-meal-shipping-shedule{
        margin: -2vw 0 2vw 0;
    }
} */

.mobile-cart-hide {
        float: left;
        width: 360px;
    }
    .meal-shipping-shedule{
        margin: 2vw 0 0 0;
        border-style: solid;
        border-color: #000000;
        border-width: 2px;
        padding: 0px 4px 0px 4px;
    }
    .meal-shipping-shedule .title {
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule .sub-title{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .meal-shipping-shedule-order-day .order-before,
    .meal-shipping-shedule-order-day .arraival-date{
        width: 47%;
        text-align: center;
    }
    .meal-shipping-shedule-order-day .order-before h3, .meal-shipping-shedule-order-day .arraival-date h3 {
        font-size: 12px;
        border-color: #000000;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day .order-before ul li,
    .meal-shipping-shedule-order-day .arraival-date ul li{
        font-size: 12px;
         font-weight: 600;
    }
    .meal-shipping-shedule .note {
        font-size: 11px;
        color: #000;
        font-weight: 600;
    }
    .cart-shedule-table{
       margin: 0 auto;
    width: 44%;
    }
    .cart-shedule-table .meal-shipping-shedule{
       margin: 0 0 1vw 0;
    }
    .cart-shedule-table .title h3{
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 30px 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table .title h4{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
  
    .checkout-meal-shipping-shedule{
        margin: -2vw 0 2vw 0;
    }

@media screen and (min-width: 1600px) {
 .mobile-cart-hide {
        float: left;
        width: 360px;
    }
    .meal-shipping-shedule{
        margin: 2vw 0 0 0;
        border-style: solid;
        border-color: #000000;
        border-width: 2px;
        padding: 0px 4px 0px 4px;
    }
    .meal-shipping-shedule .title {
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule .sub-title{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .meal-shipping-shedule-order-day .order-before,
    .meal-shipping-shedule-order-day .arraival-date{
        width: 47%;
        text-align: center;
    }
    .meal-shipping-shedule-order-day .order-before h3, .meal-shipping-shedule-order-day .arraival-date h3 {
        font-size: 12px;
        border-color: #000000;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day .order-before ul li,
    .meal-shipping-shedule-order-day .arraival-date ul li{
        font-size: 12px;
         font-weight: 600;
    }
    .meal-shipping-shedule .note {
        font-size: 11px;
        color: #000;
        font-weight: 600;
    }
    .cart-shedule-table{
       margin: 0 auto;
    width: 44%;
    }
    .cart-shedule-table .meal-shipping-shedule{
       margin: 0 0 1vw 0;
    }
    .cart-shedule-table .title h3{
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 30px 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table .title h4{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
  
    .checkout-meal-shipping-shedule{
        margin: -2vw 0 2vw 0;
    }
}
@media screen and (min-width: 1900px) {
 .mobile-cart-hide {
        float: left;
        width: 360px;
    }
    .meal-shipping-shedule{
        margin: 2vw 0 0 0;
        border-style: solid;
        border-color: #000000;
        border-width: 2px;
        padding: 0px 4px 0px 4px;
    }
    .meal-shipping-shedule .title {
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule .sub-title{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .meal-shipping-shedule-order-day .order-before,
    .meal-shipping-shedule-order-day .arraival-date{
        width: 47%;
        text-align: center;
    }
    .meal-shipping-shedule-order-day .order-before h3, .meal-shipping-shedule-order-day .arraival-date h3 {
        font-size: 12px;
        border-color: #000000;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-weight: 600;
    }
    .meal-shipping-shedule-order-day .order-before ul li,
    .meal-shipping-shedule-order-day .arraival-date ul li{
        font-size: 12px;
         font-weight: 600;
    }
    .meal-shipping-shedule .note {
        font-size: 11px;
        color: #000;
        font-weight: 600;
    }
    .cart-shedule-table{
       margin: 0 auto;
    width: 44%;
    }
    .cart-shedule-table .meal-shipping-shedule{
       margin: 0 0 1vw 0;
    }
    .cart-shedule-table .title h3{
        font-size: 19px;
        color: red;
        text-align: center;
        margin: 8px 30px 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table .title h4{
        font-size: 15px;
        color: #0062c2;
        text-align: center;
        margin: 8px 0 15px 0;
        font-weight: 600;
    }
    .cart-shedule-table-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
  
    .checkout-meal-shipping-shedule{
        margin: -2vw 0 2vw 0;
    }
}

.plan-select-error {
    color: red;
    border-color: red;
}
