/** Training **/

.trainingListContainer article {
    padding: 0px;
    line-height: 1.428571429;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 0 30px 0;
}

.trainingListContainer article:hover {
    border: 1px solid #000;
}

.course_grid_price {
    background: rgba(78, 200, 127, 0.8);
    color: #fff;
    position: absolute;
    font-size: 14px;
    right: 16px;
    top: 10px;
    padding: 3px 10px;
    z-index: 2;
    font-weight: 500;
    letter-spacing: 1px;
    width: 120px;
    height: 38px;
    line-height: 34px;
    border-radius: 6px 0 0 6px;
}

.course_grid_price:before {
    border: 4px solid transparent;
    border-top: 4px solid #5cb85c;
    border-right: 4px solid #5cb85c;
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    display: none;
}

.course_grid_price.ribbon-enrolled {
    background: rgba(75, 180, 246, 0.8);
}

.course_grid_price.ribbon-enrolled:before {
    border-top: 4px solid #428bca;
    border-right: 4px solid #428bca;
    display: none;
}

.trainingThumbImgContainer {
    height: 250px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.trainingThumbImg {
    width: 75%;
    height: auto;
    max-width: 100%;
    border-radius: 5px;
    padding: 10px;
}

.trainingListContainer .caption {
    padding: 14px 9px 9px 9px;
    color: #333333;
}

.trainingListContainer .caption h3 {
    font-size: 18px;
    color: #2f2f2f;
    height: 58px;
    font-weight: 600;
}

.trainingListContainer .caption .ld_course_grid_button {
    margin-bottom: 5px;
}

.entry-title-border {
    position: relative;
    display: block;
    height: 4px;
    width: 60px;
    background: #000;
    top: -15px;
}

.seeMore {
    border: medium none;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0 35px;
    text-transform: none;
    width: auto;
    cursor: pointer;
    background-color: #000000;
    border-color: #000000;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-decoration: none !important;
    border-radius: 20px;
}

.seeMore:hover {
    background-color: #28a745;
    border-color: #28a745;
    color: #FFF;
}

.learndash_course_content_header {
    margin: 5px 35px;
}

.learndash_course_content_title {
    color: #2f2f2f;
    font-size: 16px;
    font-weight: 600;
}

.expand_collapse {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    font-weight: 400;
}

.expand_collapse a {
    display: inline-block;
    margin: 0 5px;
    color: #131313;
    font-size: 12px;
    text-decoration: none;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0.6px;
}

.learndash_lessons {
    font-size: 12px;
    font-weight: normal;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    clear: both;
}

.learndash_lessons_header {
    background-color: #f3f3f3;
    color: #333333;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 1% 2%;
    padding: 1% 3%\9;
    font-weight: normal;
    margin: 0;
    display: block;
}

.lessons_list {
    display: table;
    width: 100%;
    margin-bottom: 0;
}

.lessons_list_sample {
    padding: 35px;
}

.lessons_list_sample table {}

.lessons_list_sample table tr th {
    color: #141414;
    background: #f5f5f5;
    border: 0px;
}

.lessons_list_sample .table-striped tbody tr:nth-of-type(odd) {
    background-color: #FFF;
}

.lessons_list_sample .table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .05);
}

.lessons_list_sample table tr td h3, .lessons_list_sample table tr td h3 a {
    font-size: 16px;
    font-weight: 600;
    color: #141414;
    text-decoration: none;
}

.CollapseAll .lessonList {
    display: none;
}

.lessonList {
    margin: 20px 0 0 40px;
}

.lessonList ul li {
    margin: 0 0px 8px 0;
}

.lessonList ul li a {
    color: #141414;
    font-size: 14px;
}

.lessonList ul li a i {
    color: #c4c4c4;
    display: inline-block;
    margin: 0 5px 0 0;
}

.statusIco {
    font-size: 18px;
}

.lessonList ul li a.completed i, .statusIco.completed {
    color: #5bbc5b;
}

.list-count {
    display: table-cell;
    width: 5%;
    text-align: center;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    color: #919191;
    font-style: italic;
}

.list-count-wrapper {
    display: table-cell;
    width: 95%;
    border-top: 1px solid #ddd;
    padding: 0;
}

.list-count-wrapper .type-list-header {
    background-position: 97.9% center;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2%;
    display: block;
    padding-right: 75px;
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
    line-height: 53px;
}

.list-count-wrapper .type-list-header.completed {
    background-image: url(../images/icon/completed.png);
}

.list-count-wrapper .type-list-header.notcompleted {
    background-image: url(../images/icon/notcompleted.png);
}

.list-count-wrapper .type-list-header:hover {
    background-color: #ddd !important;
}

.learndash_topic_dots.type-list li {
    width: 100%;
    display: block;
    background: #FFF;
    list-style-type: none;
    margin: 0;
}

.learndash_topic_dots.type-list li:hover {
    background-color: #ddd !important;
}

.learndash_topic_dots.type-list li:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.learndash_topic_dots.type-list li a {
    padding: 0 10px 0 10px;
    display: block;
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
    cursor: pointer;
}

.learndash_topic_dots.type-list li a span {
    display: inline-block;
    min-height: 28px;
    line-height: 25px;
    vertical-align: middle;
    padding-left: 30px;
    margin: 10px 0;
}

.learndash_topic_dots.type-list li a.topic-completed span {
    background: url(../images/icon/completed_t.png) no-repeat scroll 0 5px transparent;
}

.learndash_topic_dots.type-list li a.topic-notcompleted span {
    background: url(../images/icon/notcompleted_t.png) no-repeat scroll 0 5px transparent;
}

.learndash_navigation_lesson_topics_list {
    display: table-row
}

.lesson_topics_list_wrapper {
    display: table-row
}

.list_arrow.flippable {
    background-repeat: no-repeat;
    display: table-cell;
    width: 16px;
    text-align: center;
    padding: 0;
    cursor: pointer;
}

.lesson_completed {
    background-image: url(../images/icon/green_arrow_collapse.png);
    background-position: 50% 5px;
}

.lesson_incomplete {
    background-image: url(../images/icon/gray_arrow_collapse.png);
    background-position: 50% 5px;
}

.lesson_completed.expand {
    background-image: url(../images/icon/green_arrow_expand.png);
    background-position: 50% 10px;
}

.lesson_incomplete.expand {
    background-image: url(../images/icon/gray_arrow_expand.png);
    background-position: 50% 10px;
}

.lesson {
    vertical-align: top;
    display: inline-flex;
    margin: 5px;
}

.lesson a {
    text-decoration: none;
    color: #000203;
    /* font-style: italic; */
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
}

.learndash_topic_widget_list ul li {
    list-style: none;
    padding: 0;
    margin: 0 0 11px 0;
    line-height: 19px;
}

.learndash_topic_widget_list ul li a span {
    display: inline-block;
    min-height: 26px;
    line-height: 25px;
    vertical-align: middle;
    padding-left: 20px;
    color: #292828;
    font-size: 12px;
    font-weight: 500;
}

.learndash_topic_widget_list ul li a.topic-completed span {
    background: url(../images/icon/completed_t_navigation.png) no-repeat scroll 0 8px transparent;
}

.learndash_topic_widget_list ul li a.topic-notcompleted span {
    background: url(../images/icon/notcompleted_t_navigation.png) no-repeat scroll 0 8px transparent;
}

.displayShow {
    display: block;
}

.displayNone {
    display: none;
}

.learndash_content_header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    margin-bottom: 35px;
    width: 100%;
}

.learndash_content_header .vc_sep_holder {
    height: 1px;
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 10%;
}

.learndash_content_header .vc_sep_holder .vc_sep_line {
    /*border-top-width: 5px;*/
    border-color: #2A2A2A;
    height: 1px;
    border-top: 5px solid #2A2A2A;
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
}

.learndash_content h4 {
    line-height: 1em;
    margin: 0;
    word-wrap: break-word;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    padding: 0 0.8em;
    color: #2f2f2f;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
}

.lesson_download_list_subject {
    font-size: 16px;
    padding: 18px 25px;
    color: #fff;
    border: none;
    background-color: #2a2a2a;
    background-image: linear-gradient(to right, #2a2a2a 0%, #2a2a2a 50%, #2a2a2a 100%);
    transition: all .2s ease-in-out;
    background-size: 200% 100%;
    margin: 0 0 30px 0;
    text-align: center;
}

.lesson_download_list_subject a {
    color: #fff;
    display: block;
    width: 100%;
    font-style: italic;
    font-size: 16px;
    text-decoration: none;
}

.trainingContent {
    font-size: 15px;
    color: #747474;
    font-weight: 500;
    margin: 0 0 40px 0;
}

.trainingSearch {
    background-image: url(../images/icon/trainingSearchico.png);
    background-repeat: no-repeat;
    background-position: center right 12px;
    height: 35px;
    width: 370px;
    border-radius: 100px;
    outline: none;
    border: 1px solid #bfc6d6 !important;
    padding: 0 0 0 15px;
    font-size: 15px;
}

.trainingSearch::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c0c0c0;
    font-style: italic;
    /* Firefox */
}

.trainingSearch:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c0c0;
    font-style: italic;
}

.trainingSearch::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #c0c0c0;
    font-style: italic;
}

.trainingSearch:focus {
    box-shadow: none;
    border: 0px;
}

.course-navigation-aside, .trainningDownload {
    box-shadow: 0 2px 8px 0 rgba(48, 56, 59, 0.12);
    padding: 25px;
    min-height: 450px;
    border-radius: 5px;
}

.trainingVideo {
    box-shadow: 0 2px 8px 0 rgba(48, 56, 59, 0.12);
    min-height: 450px;
    border-radius: 5px;
    overflow: hidden;
}

.trainningDownloadTitle {
    font-weight: 600;
    margin: 0 0 25px 0;
}

.trainningDownloadDesc {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 45px 0;
}

.course-navigation-aside-title {
    font-size: 18px;
    line-height: 1.2em;
    margin: 0px 0 30px 0;
    font-weight: 600;
    position: relative;
}

.course-navigation-aside-title::after {
    content: "";
    position: absolute;
    display: block;
    width: 65px;
    height: 4px;
    background-color: #000;
    bottom: -10px;
}

.trainingVideoTitle {
    font-weight: 600;
    padding: 25px;
}

.trainingVideoDesc {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    padding: 25px;
}

.trainingVideoNextPrev {
    margin: 5px 25px;
}

.trainingVideoNextPrev a, .trainingVideoNextPrev a:hover {
    font-style: italic;
    font-size: 13px;
    color: #000;
    text-decoration: none !important;
    font-weight: 600;
}

.trainingBreadcrumb li {
    display: inline-block;
}

.trainingBreadcrumb li a, .trainingBreadcrumb li a:hover {
    color: #141414;
    font-size: 13px;
    text-decoration: none !important;
    border-right: 1px solid #000;
    font-style: italic;
    display: inline-block;
    font-weight: 600 !important;
    text-transform: capitalize;
    padding: 0 7px 0 0;
    margin: 0 7px 0 0;
    line-height: 15px;
}

.trainingBreadcrumb li:last-child a {
    border: 0px;
}

/** End Training **/

/** quizzes **/

.quizzes {
    margin: 30px 0 0 0;
}

.quizzesTitle {
    background-image: url(../images/icon/notcompleted.png);
    background-position: center right 10px;
    background-repeat: no-repeat;
    background-size: 25px;
    width: 100%;
}

.quizzesTitleCompleted {
    background-image: url(../images/icon/completed.png);
}

.quizzes table tr td {
    padding: 0px;
    vertical-align: middle;
}

.quizzes table tr th {
    background-color: #f5f5f5;
    padding: 10px;
    font-size: 14px;
}

.quizzes table tr td a, .quizzes table tr td a:hover {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-decoration: none !important;
    color: #212529;
    display: block;
    padding: 10px;
}

.quizzes table tr:hover td:last-child {
    background-color: #EBEBEB;
}

.StartQuizBtn {
    margin: 0;
}

.QuizQuestionSl {
    margin: 30px 0 0 0;
}

.QuizQuestionSl span {
    display: block;
    margin: 0 0 20px 0;
}

.QuizQuestionSl div {
    margin: 0;
}
.QuizQuestionSl .questionList {
    margin: 0 0 15px 0;
}

.totalQuestion {
    display: block;
    margin: 0 0 10px 0;
    margin-bottom: 10px;
    color: #000000;
    font-weight: 500;
    font-size: 15px;
}

.question {
    font-weight: 500;
}

.questionList {
    padding: 10px;
    background: #f3f3f3;
    border: 1px solid #efefef;
    border-radius: 4px;
    margin: 0 0 15px 0;
}

.questionList ul li label {
    font-size: 14px;
    font-weight: 500;
}

.QuizQuestionSl a {
    font-weight: 500;
}

.QuizQuestionStart, .QuizQuestionLoader, .QuizQuestionResults {
    margin: 20px 0 0 0;
}

.QuizQuestionResults h3 {
    margin-bottom: 30px;
}

.QuizQuestionResults a {
    margin: 0 10px 0 0;
}

.QuizQuestionResults span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin: 0 0 15px 0;
}

.QuizQuestionResults p {
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin: 30px 0 40px 0;
    text-align: center;
}

.QuizQuestionViews .QuizQuestionSl {
    margin-bottom: 20px;
}

/** End quizzes **/

.result_in_progress{
    font-size: 13px;
    font-style: italic;
    color: #141414;
}
.progress-bar {
    background-color: #4eb662;
}

.vc_separator.wpb_content_element.vc_separator_align_center.vc_sep_width_100.vc_sep_border_width_5.vc_sep_pos_align_center.vc_sep_color_black.vc_separator-has-text {
    position: relative;
    text-align: center;
}

.vc_separator.vc_separator_align_center::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 10px;
    height: 5px;
    width: 100%;
    background-color: #000;
    left: 0;
}

.vc_separator.vc_separator_align_center h4 {
    position: relative;
    text-align: center;
    display: inline-block;
    background: #FFF;
}

.vc_row.wpb_row.vc_row-fluid {
    overflow: hidden;
}

.vc_col-sm-6, .vc_col-sm-4 {
    float: left;
}

.vc_col-sm-6 {
    width: 49%;
}

.vc_col-sm-4 {
    width: 33%;
}

.vc_btn3-container.vc_btn3-center .vc_btn3-block.vc_btn3-size-lg {
    font-size: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 25px;
    padding-right: 25px;
    color: #f7f7f7;
    background-color: #1b1b1b;
    display: block;
    text-decoration: none !important;
    font-style: italic;
}

.vc_btn3-container {
    margin: 10px;
}

.vc_color-grey.vc_message_box {
    color: #858585;
    border-color: #d2d2d2;
    background-color: #EBEBEB;
    padding: 15px;
}

.vc_message_box-icon {
    width: 30px;
    height: 20px;
}

.vc_message_box-icon::after {
    font: normal normal normal 18px/1 FontAwesome;
    content: "\f05a";
}

.vc_message_box-icon, .vc_color-grey.vc_message_box p {
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
}
.vc_color-grey.vc_message_box p {
    font-size: 14px;
    font-style: italic;
    width: 92%;
}
