/******** header css ***********/
.pr_12 {
    padding-right: 12px;
}
.height_37 {
    height: 37px !important;
}
.search_icon {
    width: 37px;
    height: 37px;
    background-color: var(--sec_color);
    border-radius: 0 4px 4px 0;
    right: 0;
    top: 0;
}
.search_pro_form .form-control {
    padding-right: 40px !important;
}
.site_menu .sub_header_menu li a.menu_item {
    color: var(--text_color);
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-family: 'futura_reg';
    margin: 0;
    text-transform: uppercase;
    letter-spacing: inherit;
}
.site_menu .sub_header_menu li:not(:last-child) {
    margin-right: 15px;
}
.sub_header {
    height: auto;
}
.header_blk .sub_header .container-fluid {
    padding: 9.5px 58px;
    background-color: var(--def_color);
}   
.mb_22 {
    margin-bottom: 22px !important;
}
.order_page_banner .banner_right_img {
    width: 100% !important;
    height: auto;
}
.order_page_banner .refer_banner_slide  {
    height: 470px;
    background-image: url('../images//affiliate_imges/banner_overlay.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}
/******** header css - Ends ***********/

/******** order page css ***********/
.order_container {
    background-color: #F5F5F5;
    padding: 45px 55px;
}
.product_search_input {
    width: calc(75% - 200px);
}
.product_search_input.form_input_blk .form-control {
    background-color: #E6E5E8;
    border-color: #E6E5E8;
    height: 46px;
    padding: 10px 60px 10px 18px;
}
.product_search_input .search_icon {
    width: 47px;
    height: 47px;
    background-color: var(--pri_color);
}
.product_search_input .search_icon img {
    width: 20px;
}
.mb_10 {
    margin-bottom: 10px;
}
.pl_16 {
    padding-left: 16px;
}
.ml_19 {
    margin-left: 19px;
}
.order_pro_list {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 21px;
}
.subscri_blk {
    padding: 8px 14px;
    background-color: #F5F5F5;
    border-radius: 8px;
}
.order_pro_img, .order_pro_img img {
    width: 92px;
}
.order_pro_det {
    width: calc(100% - 92px);
}
.sub_lft {
    width: 22px;
}
.sub_rgt  {
    width: calc(100% - 22px);
}
.subscri_blk.checkbox_label.radio_label .checbox {
    width: 22px;
    height: 22px;
}
.subscri_blk.checkbox_label input {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    margin: 0;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
}
.mr_19 {
    margin-right: 19px !important;
}
.select_flavour {
    background-color: transparent;
    border: none;
    padding: 0 20px 0 0!important;
    height: 18px;
    outline: none !important;
    appearance: none;
    background-image: url('../images/affiliate_imges/sub_dropdown.svg');
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: calc(100%);
}
.subscri_blk.choose_fla_blk {
    width: 220px;
}
.order_pro_btn .quan_cart_blk .cart_add_product_btn {
    width: 170px;
    font-family: 'futura_reg' !important;
    font-weight: 400;
    border-radius: 8px;
    margin-left: 19px;
}
.order_pro_btn .quan_cart_blk .refer_product_input_group {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    border-radius: 8px;
    width: calc(42% - 9px);
}
.order_pro_btn .quan_cart_blk .refer_product_input_group .form-control {
    background-color: #f5f5f5;
}
/* .site_menu .sub_header_menu li a.menu_item.active::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--pri_color);
    position: absolute;
    left: 0;
    bottom: -11px;
} */
.cart_page_container.aff_cart_page_container {
    background-color: #F5F5F5;
    padding: 42px 0;
    min-height: calc(100vh - 138px);
}
/******** order page css - Ends ***********/

/******** cart page css ***********/
.cart_pro_list.aff_cart_pro_list {
    background-color: var(--def_color);
}
.cart_subscri_blk .sub_lft {
    width: 15px;
}
.cart_subscri_blk .sub_rgt {
    width: calc(100% - 15px);
}
.subscri_blk.cart_subscri_blk.checkbox_label.radio_label .checbox {
    width: 15px;
    height: 15px;
}
label.subscri_blk.cart_subscri_blk {
    width: fit-content;
}   
.cart_rgt_blk.white_bg {
    background-color: var(--def_color);
}
.site_menu.fixed-header .header-logo-fix a img {
    width: 130px;
    margin-right: 30px;
}
.site_menu.fixed-header .header-logo-fix a {
    display: flex;
}
/******** cart page css - Ends ***********/

/******** cart page css ***********/
.modal .modal-body {
    padding: 20px;
}
.modal .modal-footer {
    padding: 15px 20px;
}
.modal .modal-body-content {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    margin: 18px 0 0 0;
}
.modal .modal-header .close {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 20px;
}
.modal-footer .cus_button {
    font-family: 'futura_reg' !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background: var(--pri_color) !important;
    border-radius: 8px;
    padding: 13px 26px!important;
    height: inherit;
}
/******** cart page css - Ends ***********/

/******** css changes on 22/03/2023 css ***********/
.afi_dah_show.dropdown-menu {
    transform: inherit !important;
    border: none;
    margin: 0;
    padding: 0;
}
.afi_dah_show.dropdown-menu ul li {
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
}
.afi_dah_show.dropdown-menu ul li a {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    color: #4E495D;
    font-family: 'futura_bold' !important;
    padding: 14px 10px 14px 56px;
}
.afi_dah_show.dropdown-menu ul li a.active {
    color: var(--def_color);
    background-color: var(--pri_color);
}
.add_item_blk 
 .order_pro_btn .quan_cart_blk .cart_add_product_btn {
    width: calc(60%);
}
.border_btn {
    border: none !important;
    background-color: transparent !important;
}
.can_btn_ul>* {
    margin-right: 25px !important;
}
.w-auto {
    width: auto !important;
}
.sub_billing_blk 
 .vertical_cus_tab_content {
    width: calc(100% - 190px);
    margin: 0;
    padding-left: 16px;
}
.action_th>* {
    margin-left: 25px;
}
.mt_10 {
    margin-top: 10px;
}
.checkbox_label .checbox + span {
    width: calc(100% - 14px);
}
.mt_40 {
    margin-top: 40px;
}
.one_time_span {
    display: none;
}
/* .switch_label {
    width: 30px;
    height: 15px;
    border-radius: 11px;
    border: 1px solid var(--pri_color);
    position: relative;
    cursor: pointer;
}
.switch_label::after {
    width:  10px;
    height: 10px;
    position: absolute;
    content: "";
    display: block;
    left: 0.5px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ddd;
    border-radius: 50%;
    transition: 0.5s ease-in-out;
}
.switch_label_div input {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    width: 30px;
    opacity: 0;
    height: 15px;
    left: 0;
} */
.rec_span {
    display: block;
}
/* .switch_label_div input:checked + .switch_label {
    background-color: var(--pri_color);
}
.switch_label_div input:checked + .switch_label::after {
    background-color: var(--def_color);
    left: 17px;
} */
.switch_label input:checked + .switch_span + .rec_one_label .one_time_span {
    display: block;
}
.switch_label input:checked + .switch_span + .rec_one_label .rec_span {
    display: none;
}
/******** css changes on 22/03/2023 page css - Ends ***********/

/******** css changes on 22/03/2023 page css ***********/
.leader_board_blk .vertical_cus_tab_wrapper {
    padding: 0;
    border: none;
    background-color: var(--def_color);
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
}
.leader_board_blk .vertical_cus_tab li a {
    width: 100%;
    float: none;
    display: block;
    border: none !important;
    font-weight: 700;
    font-family: 'futura_bold' !important;
    font-size: 14px;
    line-height: 17px;
    padding: 11px 20px;
    color: #4E495D;
    margin: 0;
    background-color: var(--def_color);
}
.leader_board_blk .nav-tabs .nav-item {
    width: 100%;
}
.leader_board_blk .vertical_cus_tab li a.active {
    background-color: var(--pri_color);
    color: var(--def_color) !important;
}
.leader_board_blk .vertical_cus_tab_content {
    width: calc(100% - 250px);
    margin: 0;
    padding-left: 30px;
}
table.dataTable.subscribtion_table th {
    border: none !important;
    padding-top: 0 !important;
    background-image: none !important;
}
.subscribtion_table tr td {
    border: none !important;
}
table.dataTable.subscribtion_table {
    border-collapse: separate;
    border-spacing: 0 9px;
    border: none !important;
}
table.dataTable.subscribtion_table tr td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
table.dataTable.subscribtion_table tbody tr {
    background-color: transparent;
}
table.dataTable.subscribtion_table tr td.dataTables_empty {
    border-radius: 8px;
}
.dataTables_wrapper .dataTables_filter {
    float: left !important;
}
.dataTables_wrapper .dataTables_length {
    float: right !important;
}
.dataTables_wrapper .dataTables_filter label, .dataTables_wrapper .dataTables_length label, .dataTables_wrapper .dataTables_info {
    margin: 0;
    font-size: 16px !important;
    line-height: 22px;
    font-weight: 400;
    font-family: 'futura_reg' !important;
}
.dataTables_wrapper .dataTables_filter label input[type="search"] {
    margin: 0 !important;
    background: var(--def_color);
    border-radius: 4px;
    height: 42px !important;
    width: 250px;
    font-size: 14px;
    line-height: 17px;
    padding: 10px !important;
    border: none !important;
}
.dataTables_wrapper .dataTables_length label select {
    border: none;
    font-size: 12px;
    line-height: 14px;
    width: 68px;
    color: rgba(34, 28, 53, 0.5);
    font-weight: 700;
    font-family: 'futura_bold' !important;
    appearance: none;
    height: 30px;
    border-radius: 45px;
    padding: 4px 20px 4px 10px;
    background-image: url('../images/customer_images/order_dropdown.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px);
    margin: 0 10px !important;
}
.report_date_range {
    width: calc(100% - 30px) !important;
    margin: 0 auto !important;
    background-color: var(--def_color);
    border-radius: 4px;
    border: 1px solid var(--text_color);
}
.tab_search.report_tab_search {
    width: 100%;
    background-color: var(--def_color);
    border: none !important;
    padding: 11px 20px;
}
.report_tab_search 
 .tab_rep_search {
    font-size: 14px !important;
    width: 74px;
    margin: 0 5px !important;
    background-color: var(--def_color);
    border-radius: 4px;
    border: 1px solid var(--text_color);
    height: 30px;
}
.mr_5 {
    margin-right: 5px !important;
}
body .select2-container--default .select2-selection--multiple {
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    padding: 8px 11px;
    font-weight: 400;
    font-size: 16px;
    height: 42px;
    color: var(--pri_color);
    line-height: 18px;
    font-family: 'futura_reg';
    overflow: auto;
}
body .select2-container {
    width: 100% !important;
}
body .select2-container--default .select2-selection--multiple li {
    white-space: normal;
}
body .select2-container--default .select2-selection--multiple li input {
    margin: 0 !important;
    padding-top: 3px !important;
}
.checkout_left_blk  .checkbox_label {
    width: 100%;
}
/******** css changes on 22/03/2023 page css - Ends ***********/

/******** css changes on 24/03/2023 page css  ***********/
.commission_label_div>label {
    width: 150px;
    padding-right: 20px;
}
.commission_label_div>.form-control {
    width: calc(100% - 150px);
}
.commission_label_div {
    margin-bottom: 10px;
    position: relative;
}
span.percentage_span {
    position: absolute;
    right: 17px;
    top: 10px;
}
.form_input_blk.add_team_member_blk .form-control {
    padding-right: 40px !important;
}
.sub_lbel_container {
    width: 250px;
    margin-left: auto;
}
.checkbox_label .checbox + span {
    text-align: left;
}
.thankyou_page .total_span, .thankyou_page .price_span {
    width: 50%;
}
/******** css changes on 24/03/2023 page css - Ends ***********/

/******** css changes on 27/03/2023 page css ***********/
.table_search_select 
 .search_input_btn {
    width: 38px !important;
    height: 38px;
    background-color: var(--def_color);
    border-radius: 4px;
    border: 1px solid var(--def_color);
    background-image: url('../images/customer_images/header_search_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.site_menu .sub_header_menu li a.menu_item {
    display: flex;
}
/******** css changes on 27/03/2023 page css - Ends ***********/

/******* 28/03/2023 css ***********/
.shop_menu_li img {
    width: 100%;
    height: 92px;
    object-fit: contain;
}
.user_login.user_login-new {
    margin: 0;
}
.progress_hover {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1px;
    pointer-events: none;
    color: green;
}
.font_11 {
    font-size: 11px;
    line-height: 14px;
}
.progress_show:hover + .hide {
    display: block;
}

.subscribtion_mont_row {
    border-top: 1px solid #F5F5F5;
    margin-top: 20px !important;
    padding-top: 20px;
    margin-left: auto !important;
}

.user_login.user_login_join {
    margin: 0;
}
.modal-backdrop.fade {
    display: none;
}
.modal {
    background-color: rgba(0,0,0,0.5);
}
/******* 28/03/2023 css - Ends ***********/

/******* 29/03/2023 css ***********/
.remember_me_label.custom-control.custom-checkbox {
    min-height: inherit;
    display: flex;
    align-items: center;
    padding-left: 21px;
}
.remember_me_label.custom-control.custom-checkbox .custom-control-label::after, .remember_me_label.custom-control.custom-checkbox .custom-control-label::before {
    top: 50%;
    transform: translateY(-50%);
}
.remember_me_label.custom-control.custom-checkbox .custom-control-label::before {
    border: 1px solid var(--pri_color);
    border-radius: 1px;
    width: 11px;
    display: block;
    height: 11px;
    background-color: var(--def_color);
    left: -21px;
}
.remember_me_label.custom-control.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--pri_color);
}
.remember_me_label.custom-control.custom-checkbox .custom-control-label::after {
    background-size: 6px;
    background-position: center;
    width: 11px;
    left: -21px;
    height: 11px;
}
/******* 29/03/2023 css - Ends ***********/

/******* 30/03/2023 css ***********/
.pro_des_aff p strong {
    font-weight: 400;
}
.pro_des_aff p {
    margin: 0;
    padding: 0 !important;
}
/******* 30/03/2023 css - Ends ***********/


/******* 31/03/2023 css ***********/
.no_record_found {
    padding: 20px;
    background-color: var(--def_color);
    text-align: center;
}
.reg_error .alert-wrapper.fixed-header {
    position: fixed;
    z-index: 2;
    width: 450px;
    top: 44px;
}
/******* 31/03/2023 css - Ends ***********/

/******* 14/04/2023 css ***********/
.w-fit-content {
    width: fit-content;
}
.btn_cancel.buy_now {
    padding: 13.5px 30px;
    margin-left: 20px;
}
.mt_23 {
    margin-top: 23px;
}
.w-35 {
    width: 35px;
}
.modal-content .col-md-12 {
    width: 100%;
}
/******* 14/04/2023 css - Ends ***********/

/******* 05/03/2024 css ***********/
.pay_lk.authorize_blk>input[type="radio"].disabled  {
    pointer-events: none;
}
.pay_lk.authorize_blk>input[type="radio"].disabled + .pay_header {
    background-color: rgb(242 242 242 / 60%);
    margin-bottom: 40px !important;
}
.pay_lk.authorize_blk>input[type="radio"].disabled + .pay_header>span {
    position: absolute;
    bottom: -18px;
    left: 0;
}
.pay_lk.authorize_blk>input[type="radio"].disabled + .pay_header label, .pay_lk.authorize_blk>input[type="radio"].disabled + .pay_header .auth_div {
    opacity: 0.6;
}

.width_fit_content {
    width: max-content;
}
.order_pro_list .cart_quantity.quan_cart_blk .refer_product_input_group, .order_pro_list .quan_cart_blk .refer_product_input_group .input-group-prepend button {
    height: 45px;
}
.product_ti_des {
    width: 33%;
    padding-right: 10px;
}
.flavour_accordation .card-header {
    border: none;
    background: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
}
.flavour_accordation {
    width: 330px;
}
.flavour_accordation .card-header .flavour_acc_header {
    padding: 6.5px 14px;
    height: 45px;
}
.flavour_accordation .card-bodys {
    padding: 11px 14px;
}
.flavour_accordation .card {
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px !important;
}
.flavour_accordation .card-header .flavour_acc_header.collapsed .flavour_header_left.hide {
    display: none !important;
}
.flavour_accordation .card-header .flavour_acc_header .flavour_header_left.hide {
    display: flex !important;
}
.flavour_accordation .card-header .flavour_acc_header .flavour_header_left.show {
    display: none !important;
}
.flavour_accordation .card-header .flavour_acc_header.collapsed .flavour_header_left.show {
    display: flex !important;
}
.flavour_accordation .card-header .flavour_acc_header.collapsed .flavour_dropdown {
    transform: rotate(0Deg);
}
.flavour_accordation .card-header .flavour_acc_header .flavour_dropdown {
    transform: rotate(-180Deg);
}
.flavour_header_left {
    width: calc(100% - 14px);
    padding-right: 20px;
}
.product_det_flavour {
    height: 29px;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    line-height: 17px;
    background-image: url('../images/refer_images/delivery_dropdown.svg');
    appearance: none;
    background-position: calc(100% - 7px);
    padding: 1px 25px 1px 7px;
    background-repeat: no-repeat;
    outline: none !important;
}
.flavour_accordation .acc_collapse {
    position: absolute;
    width: 100%;
    background-color: #F5F5F5;
    top: 43px;
    z-index: 3;
}
/******* 05/03/2024 css - Ends ***********/

