/******* home css ***********/
.mb_90 {
    margin-bottom: 90px;
}
.banner_right_img {
    height: 500px;
    width: auto !important;
}
.banner_heading {
    color: #D3D2D7;
    font-size: 67.778px;
    line-height: 87px;
    width: 376px;
    text-transform: uppercase;
}
.buy_now {
    background-color: var(--pri_color);
    border-radius: 6px;
    border: none;
    padding: 13.5px 50.5px;
}
.mr_36 {
    margin-right: 36px !important;
}
.view_detail {
    background-color: var(--new_button_color);
    border-radius: 6px;
    border: none;
    padding: 13.5px 42.5px;
}
button {
    cursor: pointer;
}
.refer_banner_slide .carousel-indicators li {
    width: 9px;
    height: 9px;
    background: rgba(242, 242, 242, 0.4);
    border-radius: 50%;
    margin: 0 6.5px;
}
.refer_banner_slide .carousel-indicators li.active {
    background-color: var(--def_color);
}
.refer_banner_slide .carousel-indicators li::after, .refer_banner_slide .carousel-indicators li::before {
    display: none;
}
.refer_banner_slide .carousel-indicators {
    margin: 0;
    bottom: 33px;
}
.banner_text {
    color: #FFFFFF;
}
.pl_12 {
    padding-left: 12px;
}
.mb_17 {
    margin-bottom: 17px;
}
.farmulated_reound {
    width: 46px;
    height: 46px;
    border: 2px solid #BDBBC2;
    border-radius: 50%;
}
.carousel .farmulated_reound img {
    width: auto;
}
.formulated_ul {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
}
.farmulated_reound + * {
    width: calc(100% - 46px);
}
.font_41 {
    font-size: 41.8912px;
    line-height: 65px;
}
.gray_bg {
    background-color: var(--sec_color);
}
.best_selling_section {
    padding: 44px 21px;
    display: block;
}
.mb_49 {
    margin-bottom: 49px;
}
.best_sell_pro_div .refer-img-wrapper {
    margin-bottom: 21px;
    margin-left: auto;
    margin-right: auto;
}
.best_sell_pro_div .refer-img-wrapper a {
    width: 140px;
    position: relative;
}
.best_sell_pro_div .refer-img-wrapper a::after {
    content: "";
    width: 100%;
    height: 42px;
    display: block;
    background-color: #B7B5BE;
    filter: blur(15px);
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    z-index: 1;
}
.best_sell_pro_div .refer-img-wrapper a img {
    position: relative;
    z-index: 2;
}
.font_20 {
    font-size: 20px;
    line-height: 31px;
}
.text-uppercase {
    text-transform: uppercase;
}
.mb_2 {
    margin-bottom: 2px;
}
.mb_24 {
    margin-bottom: 24px;
}
.pro_price {
    background-color: var(--pri_color);
    border-radius: 6px;
    padding: 8px 13px;
}
.best_selling_para {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
}
.para_des {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.line_through {
    text-decoration: line-through;
}
.mt_7 {
    margin-top: 7px;
}
.view_more_div a {
    padding: 0 !important;
}
.mb_28 {
    margin-bottom: 28px;
}
.explore_product_section {
    padding: 57px 30px 103px 30px;
    background-color: #A7A4AE;
}
.explore_img {
    border-radius: 50%;
    width: 257px;
    height: 257px;
    background-color: var(--sec_color);
}
.mb_41 {
    margin-bottom: 41px;
}
.exp_col_col {
    width: 63%;
    margin: 0 auto;
}
.mb_20 {
    margin-bottom: 20px;
}
.shop_goal_section {
    padding: 55px 38px 80px 38px;
}
.goal_row.row {
    margin-left: -6.5px;
    margin-right: -6.5px;
    width: calc(100% + 13px);
}
.goal_row.row>* {
    padding-left: 6.5px;
    padding-right: 6.5px;
}
.shop_col {
    padding: 45px 50px;
    background-color: var(--def_color);
}
.shop_more {
    background-color: var(--pri_color);
    border-radius: 6px;
    padding: 6px 15px;
}
.shop_col .font_14 {
    line-height: 20px;
}
.shop_img_rect {
    background: linear-gradient(180deg, #A7A4AE 0%, rgba(167, 164, 174, 0) 96.69%);
    border-radius: 26px 0px 0px 0px;
    width: 253px;
    height: 243px;
    margin-left: auto;
    margin-bottom: -45px;
    margin-right: -50px;
}
.shop_img_rect img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.mt_25 {
    margin-top: 25px;
}
.pl_42 {
    padding-left: 42px;
}
.Testimonial_row.row {
    margin-left: -11px;
    margin-right: -11px;
    width: calc(100% + 22px);
}
.Testimonial_row.row>* {
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 22px;
}
.explore_product_section.testimonial_section {
    padding: 65px 38px;
}
.testsimonial_col {
    padding: 20px 50px 55px 50px;
    background-color: var(--def_color);
}
.mt_28 {
    margin-top: 28px;
}
.mb_14 {
    margin-bottom: 14px;
}
.fitness_text {
    font-size: 67.778px;
    line-height: 85px;
    text-transform: capitalize;
}
section.your_fitness_section {
    padding: 60px 38px 50px 15px;
}
.fitness_left {
    width: 540px;
}
.fitness_rgt {
    width: calc(100% - 540px);
}
.fitness_para {
    font-size: 22px;
    line-height: 25px;
}
.our_story_section {
    padding: 81px 38px;
}
.play_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.our_story_section .container {
    background-color: var(--def_color);
}
.our_story_left  {
    width: 630px;
}
.our_story_right {
    width: calc(100% - 630px);
    padding: 57px;
}
.shop_more.take_quiz_btn {
    padding: 12px 30px;
}
.best_selling_pro .row > * {
    display: flex;
    align-items: center;
}
/******* home css - Ends ***********/

/******* footer css ***********/
.refer_footer {
    margin: 0 !important;
}
.fw_reg {
    font-weight: 400 !important;
}
.site-footer.refer_footer {
    background-color: var(--text_color);
    padding: 4px 80px 0 35px;
}
.sent_mail_input {
    background-color: #D9D9D9;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 45px;
    padding: 5px 50px 5px 15px;
}
.sent_mail_input::-webkit-input-placeholder {
    color: var(--pri_color);
}
.send_mail_link {
    position: absolute;
    right: 0;
    top: 0;
}
.mb_16 {
    margin-bottom: 16px;
}
.pl_10 {
    padding-left: 10px;
}
.add_sec>*:not(:last-child) {
    margin-bottom: 16px;
}
.location_icon {
    position: relative;
    top: 2px;
}
.mb_27 {
    margin-bottom: 27px;
}
.footer_menu li:not(:last-child) {
    margin-bottom: 7px;
}
.social_media_link>*:not(:last-child) {
    margin-right: 13px;
}
/******* footer css - Ends ***********/

/******* suplements css ***********/
.suplements_blk {
    padding: 45px 0;
}
.pro_breadcrumb li::before {
    display: none !important;
}
.pro_breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: 6px;
    color: #4E495D;
}
.sup_pro_row {
    margin-left: -14px;
    margin-right: -14px;
    width: calc(100% + 28px);
}
.sup_pro_row>* {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 28px;
}
.refer_produc_wrapper.sup_pro_col {
    margin: 0;
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 35px 20px;
}
a:hover {
    text-decoration: none;
}
a.text-underline:hover {
    text-decoration: underline;
}
.refer-img-wrapper {
    width: 148px;
}
.mb_4 {
    margin-bottom: 4px;
}
.mb_9 {
    margin-bottom: 9px;
}
.pl_3 {
    padding-left: 3px;
}
.shop_more.add_cart_btn {
    padding: 6px 13px;
}
.pl_7 {
    padding-left: 7px;
}
.pro_filter_sec {
    background-color: #4E495D;
    border-radius: 8px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 20px;
    border: 1px solid;
}
.pro_filter_sec>*:not(:last-child) {
    margin-right: 31px;
}
.filter_select .btn-primary {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    font-size: 16px;
    line-height: 18px;
    box-shadow: none !important;
    display: flex;
    align-items: center;
}
.filter_select .dropdown-menu .dropdown-item {
    color: var(--pri_color);
    font-size: 16px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    line-height: 18px;
    padding: 5px 10px;
    background-color: transparent !important;
}
.filter_select .dropdown-menu {
    padding: 0;
}
.filter_select .dropdown-toggle::after {
    width: 7px;
    margin-left: 4px;
    height: 7px;
    background-image: url('././../images/refer_images/filter_dropdown.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}
/******* suplements css - Ends ***********/

/******* rating css ***********/
.rate {
    flex-direction: row-reverse;
    justify-content: center;
}
.five_star label, .four_star label:nth-child(4), .four_star label:nth-child(6), .four_star label:nth-child(8), .four_star label:nth-child(10),
.three_star label:nth-child(6), .three_star label:nth-child(8), .three_star label:nth-child(10), .two_star label:nth-child(8), .two_star label:nth-child(10),
.one_star label:nth-child(10) {
    color: #FFD200 !important;    
}
.rate.disabled label {
    pointer-events: none;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 13px;
    margin: 0;
    line-height: 13px;
    color: #ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #FFD200;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #FFD200;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #FFD200;
}
/******* rating css - Ends ***********/

/******* product css  ***********/
.pro_left_blk {
    background: rgba(242, 242, 242, 0.5);
    padding: 40px 30px 80px 60px;
}
.mb_44 {
    margin-bottom: 44px;
}
.pro_breadcrumb.cus_breadcrumb li {
    font-size: 16px;
    color: #4E495D;
    line-height: 18px;
}
.pro_breadcrumb.cus_breadcrumb li a {
    color: var(--text_color);
    display: flex;
}
.pro_left_blk  .mkn-img-container {
    border: none;
    background: none;
    margin: 0 auto 69px auto;
}
.pro_left_blk .carousel .thumbs {
    justify-content: center;
    display: flex;
}
.pro_left_blk .carousel .thumbs li.thumb {
    width: 96px !important;
    height: 106px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    border: 1px solid transparent !important;
}
.pro_left_blk .carousel .thumbs li.thumb:not(:last-child) {
    margin-right: 10px !important;
}
.pro_left_blk .carousel .thumbs li.thumb.selected {
    background-color: var(--def_color);
    border-color: #DAD7E3;
}
.pro_left_blk .slider-wrapper.axis-horizontal {
    width: 227px;
}
.pro_rgt_blk {
    padding: 32px 50px;
}
.font_24 {
    font-size: 24px;
    line-height: 28px;  
}
.ml_13 {
    margin-left: 13px;
}
.ml_16 {
    margin-left: 16px;
}
.dis_span {
    background-color: var(--sec_color);
    border-radius: 4px;
    padding: 4px 11px;
}
.pl_11 {
    padding-left: 11px;
}
.detail_rate.rate > label {
    font-size: 22px;
    line-height: 17px;
    width: 17px;
    margin-left: 7px;
}
.mb_25 {
    margin-bottom: 25px;
}   
.pro_para  {
    line-height: 24px !important;
}
.pl_5  {
    padding-left: 5px;
}
.pro_des  li {
    margin-right: 24px;
    margin-bottom: 8px;
}
.choose_text {
    color: #4E495D;
}
.choose_flavour .swatch_block div label {
    margin-right: 0;
    background-color: var(--sec_color);
    border: none;
    border-radius: 4px;
    padding: 12px 21px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.choose_flavour .swatch_block>div {
    margin-bottom: 13px;
    margin-right: 13px;
}
.choose_flavour .swatch_block div input {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}
.choose_flavour .swatch_block div input:checked + label {
    background-color: rgba(34, 28, 53, 0.77);
    color: var(--def_color);
}
.pl_35 {
    padding-left: 35px;
}
.del_opt_div {
    background-color: var(--sec_color);
    border-radius: 4px;
    padding: 13px 17px;
}
.del_left {
    width: calc(100% - 14px);
    padding-right: 20px;
}
.quan_cart_blk 
 .refer_product_input_group {
    width: 140px;
    background: var(--def_color);
    border: 1px solid var(--pri_color);
    border-radius: 5px 0px 0px 5px;
    height: 45px;
}
.quan_cart_blk .refer_product_input_group .input-group-prepend button {
    background-color: transparent !important;
    padding: 5px 7px;
    color: var(--pri_color);
    font-weight: 400 !important;
    box-shadow: none !important;
    outline: none !important;
    font-family: 'futura_reg' !important;
    font-weight: 400;
    line-height: 20px !important;
    height: 27px;
}
.quan_cart_blk .refer_product_input_group .form-control {
    height: 100%;
    border: none;
    font-family: 'futura_reg' !important;
    box-shadow: none !important;
    text-align: center !important;
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);
    color: var(--pri_color);
    font-size: 22px;
    background-color: var(--def_color);
    line-height: 25px;
}
.quan_cart_blk .cart_add_product_btn {
    width: calc(100% - 41px);
    height: 45px;
    border-radius: 0px 6px 6px 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    font-family: 'futura_bold' !important;
    background: var(--pri_color) !important;
}
.product_tab_section {
    background-color: var(--pri_color);
    padding: 50px 0 0 0; 
}
.product_tabs {
    border: none;
    justify-content: center;
    margin-bottom: 14px;
}
.product_tabs>li:not(:last-child) {
    margin-right: 50px;
}
.product_tabs>li .nav-link {
    margin-right: 50px;
    color: #B4B1BC;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    font-family: 'futura_bold';
    padding: 0 0 5px 0!important;
    background-color: transparent !important;
    position: relative;
    border: none !important;
    outline: none !important;
}
.product_tabs>li .nav-link.active {
    background-color: transparent;
    border: none;
    color: var(--def_color);
}
.product_tabs>li .nav-link.active::after {
    content: "";
    display: block;
    width: 100%;
    height: 1.5px;
    background-color: var(--def_color);
    position: absolute;
    bottom: 0;
    left: 0;
}
.benefit_text {
    color: #D3D2D7;
}
.font_36 {
    font-size: 36px;
    line-height: 56px;
}
.mb_11 {
    margin-bottom: 11px;
}
.font_18 {
    font-size: 18px;
    line-height: 21px;
}
.pl_34 {
    padding-left: 34px;
}
.benefit_ul {
    width: fit-content;
}
.benefit_ul li {
    padding: 13px 0 10px 0;
    border-bottom: 1px solid #545454;
}
.ben_letf {
    width: 406px;
}
.ben_rgt {
    width: calc(100% - 406px);
    padding-left: 105px;
}
.mb_39 {
    margin-bottom: 39px;
}
.how_to_use_col {
    background-color: #302945;
    border-radius: 13px;
    padding: 22px 32px;
}
.how_one_text {
    font-weight: 700;
    font-size: 105px;
    line-height: 164px;
    color: #D3D2D7;
    opacity: 0.3;
    position: absolute;
    top: -24px;
    right: 6px;
    font-family: 'futura_bold';
    width: 78px;
    text-align: center;
}
.how_use_tab {
    padding-top: 47px;
    padding-bottom: 100px;
}
.howuse_heading {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}
.mb_19 {
    margin-bottom: 19px;
}
.mb_106 {
    margin-bottom: 109px;
}
.mb_15 {
    margin-bottom: 15px;
}
.sup_fact_left {
    width: 400px;
}
.cal_fiv {
    width: 212px;
    height: 212px;
    background-color: #302945;
    border-radius: 50%;
    position: absolute;
    right: -106px;
}
.cal_no {
    font-size: 55.89px;
    line-height: 64px;
}
.cal_text {
    font-size: 33.89px;
    line-height: 39px;
}
.sup_fact_rgt {
    background-color: #302945;
    border-radius: 8px;
    padding: 43px 25px;
    width: 550px;
}
.suplement_fact_div {
    padding: 65px 0 71px 0;
}
.pr_5 {
    padding-right: 5px;
}
.pb_15 {
    padding-bottom: 15px;
}
.fact_btm_bor {
    border-bottom: 3px solid #3B3844;
}
.mb_31 {
    margin-bottom: 31px;
}
.pb_11 {
    padding-bottom: 11px;
}
.pl_41 {
    padding-left: 41px;
}
.mb_32 {
    margin-bottom: 32px;
}
.ingredient_text {
    color: #9592A0;
}
.ingredient_blk p.font_16 {
    line-height: 24px !important;
}
.ingredient_blk .row {
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
}
.ingredient_blk .row>* {
    padding-left: 25px;
    padding-right: 25px;
}
.ingedient_col {
    background-color: #302945;
    border-radius: 4px;
    padding: 32px;
}
.ingredient_blk{
    padding: 51px 0 71px 0;
}
.mb_38 {
    margin-bottom: 38px;
}
.faq_section {
    padding: 67px 0;
}
.faq_section .container {
    width: 860px;
}
.faq_section  .accordion-header {
    margin: 0;
}
.faq_section .accordion-header .accordion-button.collapsed {
    background-color: transparent;
    padding-bottom: 13px;
    padding-top: 13px;
}
.faq_section .accordion-header .accordion-button {
    background-color: var(--def_color);
    width: 100%;
    border: none;
    outline: none;
    padding-top: 26px;
    padding-left: 29px;
    padding-right: 57px;
    padding-bottom: 0;
}
.faq_section .accordion-header .accordion-button .plus_icon, .faq_section .accordion-header .accordion-button.collapsed .minus_icon {
    display: none;
} 
.faq_section .accordion-header .accordion-button.collapsed .plus_icon {
    display: block;
}
.faq_section  .accordion-item {
    margin-bottom: 13px;
}
.faq_section .accordion-collapse {
    padding-left: 29px;
    padding-top: 6px;
    padding-right: 100px;
    padding-bottom: 33px;
}
.faq_section .accordion-collapse.show {
    background-color: var(--def_color);
}
.faq_section .accordion-collapse .font_16 {
    line-height: 24px !important;
}
.like_product_section {
    padding: 60px 0 80px 0;
}
.like_pro_para {
    width: 48%;
}
.mb_30 {
    margin-bottom: 30px;
}
.mb_45 {
    margin-bottom: 45px;
}
.white_bg {
    background-color: var(--def_color);
}
.overall_rating {
    padding: 24px 24px 50px 24px;
}
.mb_52 {
    margin-bottom: 52px;
}
.mb_11 {
    margin-bottom: 11px;
}
.pro_img {
    width: 180px;
    margin: 0 auto 22px auto;
    height: 273px;
}
.pro_img>* {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.pro_carousel .owl-carousel {
    position: static;
}
.pro_carousel .owl-prev, .pro_carousel .owl-next {
    margin: 0 !important;
    background-color: var(--pri_color) !important;
    color: var(--def_color) !important;
    height: 93px;
    width: 37px;
    border-radius: 8px 0 0 8px !important;
    position: absolute;
    font-size: 46px !important;
    line-height: 46px !important;
}
body .pro_carousel .owl-prev {
    border-radius: 0 8px 8px  0 !important;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}
body .pro_carousel .owl-next {
    border-radius: 0 8px 8px  0 !important;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
}
.review_rating_section {
    padding: 53px 0;
}
.like_pro_para.font_16 {
    line-height: 24px !important;
}
.rate.overall_rate label {
    font-size: 31px;
    line-height: 28px;
    margin: 0 6px;
}
.most_recent_sel {
    background-color: var(--def_color);
    border: 1px solid var(--pri_color);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 35px 8px 14px;
    appearance: none;
    font-family: 'futura_reg' !important;
    background-image: url('./../images/refer_images/select_dropdown.svg');
    background-position: calc(100% - 15px);
    background-repeat: no-repeat;
}
.shop_more.take_quiz_btn.write_review_btn {
    padding: 8px 30px;
}
.mb_23 {
    margin-bottom: 23px;
}
.wri_review_blk {
    padding: 40px 32px;
    margin-bottom: 24px;
}
.mb_6 {
    margin-bottom: 6px;
}
.reviewer_img img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
.reviewer_img {
    width: 64px;
}
.pl_15{
    padding-left: 15px;
}
.reviewer_det {
    width: calc(100%  - 64px);
}
.rev_left {
    width: 25%;
}
.w-60 {
    width: 60% !important;
}
.rate.reviewer_rate label {
    font-size: 16px;
    line-height: 15px;
    margin: 0 3px;
}
.mt_39 {
    margin-top: 39px;
}
/******* product css - Ends ***********/

/******* cart css ***********/
.cart_page_container {
    padding: 32px 0;
}
.cart_breadcrubmb .font_16 {
    line-height: 24px !important;
}
.pro_breadcrumb.cus_breadcrumb.cart_breadcrubmb li a {
    color: #4E495D;
}
.line_height_25 {
    line-height: 25px !important;
}
.red_text, a.red_text:hover {
    color: #FF5959 !important;
}
.cart_row {
    width: calc(100% + 22px);
    margin-left: -11px;
    margin-right: -11px;
}
.cart_row>* {
    padding-left: 11px;
    padding-right: 11px;
}
.cart_pro_list.white_bg {
    background-color: var(--def_color);
}
.cart_pro_list {
    background-color: var(--sec_color);
    margin-bottom: 18px;
    border-radius: 8px;
    padding: 15px;
}
.cart_pro_list_left  {
    width: 60%;
    padding-right: 20px;
}
.cart_quantity.quan_cart_blk {
    width: 141px;
}
.cart_quantity.quan_cart_blk .refer_product_input_group {
    border-radius: 5px;
    height: 29px;
    width: 100%;
}
.cart_quantity.quan_cart_blk .refer_product_input_group .form-control {
    font-size: 18px;
    line-height: 21px;
}
.cart_total {
    width: calc(40% - 140px);
    padding-left: 20px;
    text-align: right;
}
.cart_pro_img img {
    width: 70px;
}
.pl_13 {
    padding-left: 13px;
}
.cart_pro_det  {
    width: calc(100% - 70px);
}
.cart_rgt_blk {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 25px;
}
.coupon_input {
    background-color: var(--def_color);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    width: calc(100% - 81px);
    height: 32px;
    margin-right: 14px;
    padding: 5px 10px;
    outline: none;
}
.coupon_apply {
    background-color: var(--button_color);
    border: 0.5px solid var(--button_color);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--def_color);
    font-family: 'futura_reg' !important;
    padding: 6.5px 13px;    
    cursor: pointer;
}
.total_span {
    width: 60%;
    padding-right: 10px;
}
.price_span {
    padding-left: 10px;
    width: 40%;
}
.mb_3 {
    margin-bottom: 3px;
}
.cart_page_container {
    min-height: calc(100vh - 90px);
}
/******* cart css - Ends ***********/

/******* checkout css ***********/
.checkout_left_blk {
    padding: 30px 60px;
}
.font_11{
    font-size: 11px;
    line-height: 13px;
}
.checkout_ul {
    background-color: #D9D9D9;
    border-radius: 50px;
}
.checkout_ul li {
    width: 33.33%;
    border-radius: 50px;
    padding: 12.5px;
}
.checkout_round {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.checkout_ul li.active {
    background-color: var(--pri_color);
}
.checkout_ul li.active .font_16 {
    color: var(--def_color);
}
.height_42 {
    height: 42px !important;
}
.checkout_label label {
    font-size: 16px;
    line-height: 18px;
}
.mt_15 {
    margin-top: 15px;
}
.mt_11 {
    margin-top: 11px;
}
.checkbox_label input {
    display: none;
}
.checkbox_label .checbox {
    border: 1px solid var(--pri_color);
    border-radius: 1px;
    width: 12px;
    display: block;
    height: 12px;
    position: relative;
    background-color: var(--def_color);
}
.checkbox_label.radio_label .checbox {
    border-radius: 50%;
}
.form_input_blk .form-control:disabled {
    background-color: var(--def_color);
}
.pl_6 {
    padding-left: 6px;
}
.checkbox_label input:checked + .checbox {
    background-color: var(--pri_color);
}
.checkbox_label input:checked + .checbox::after {
   content: '';
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) rotate(-52deg);
    width: 5px;
    height: 2px;
    border-left: 1px solid var(--def_color);
    border-bottom: 1px solid var(--def_color);
}
.checkbox_label.radio_label input:checked + .checbox::after {
   content: '';
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    background-color: var(--def_color);
    border-radius: 50%;
}
.mb_12 {
    margin-bottom: 12px;
}
.w-20  {
    width: 20% !important;
}
.mr_32 {
    margin-right: 32px !important;
}
.checkout_rgt_blk {
    padding: 115px 55px;
    background-color: var(--sec_color);
}
.cart_summary_blk  {
    padding: 25px;
    border-radius: 8px;
}
.cart_pro_su {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 8px;
}
.mt_66 {
    margin-top: 66px;
}
.shop_more.cont_ship_btn:disabled {
    background: rgba(34, 28, 53, 0.3);
    color: rgba(255, 255, 255, 0.3);
}
.shop_more.take_quiz_btn.cont_ship_btn {
    padding: 13.5px 30px;
    border: none;
}
.checkout_page, .checkout_page>div>.row {
    min-height: 100vh;
}
.mt_43 {
    margin-top: 43px;
}
.shipping_blk {
    background-color: var(--def_color);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
}
.shipping_info {
    padding: 13px;
}
.shipping_info.border_btm {
    border-bottom: 1px solid var(--pri_color);
}
.checkout_ul li.active.selected {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.checkout_ul li.active.selected + .active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.mb_48 {
    margin-bottom: 48px;
}
.shipping_me_div {
    background-color: var(--def_color);
    border: 1px solid var(--pri_color);
    border-radius: 4px;
    /* padding: 10px 13px; */
}
.shipping_left {
    width: 80%;
}
.shipping_rgt {
    width: 20%;
}
.contact_label {
    width: 100px;
}
.contact_label + * {
    width: calc(100% - 100px);
    padding-left: 10px;
}
.pay_lk {
    background-color: var(--def_color);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    padding: 21px 0;
}
.pay_lk .pay_header {
    border-bottom: 0.5px solid var(--pri_color);
    padding-bottom: 15px;
    padding-left: 19px;
    padding-right: 19px;
}
.cpay_body.form_input_blk {
    padding: 18px 19px 0 19px;
}
.ml_6 {
    margin-left: 6px;
}
.payment_upi {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 14px;
    height: 100%;
}
.payment_upi span.checbox + img {
    width: calc(100% - 17px);
}
.upi_row {
    width: calc(100% + 22px);
    margin-left: -11px;
    margin-right: -11px;
}
.upi_row>* {
    padding-left: 11px;
    padding-right: 11px;
}
.order_confirmed_page {
    min-height: calc(100vh - 90px);
    background-color: #f5f5f5;
}
.line_height_38 {
    line-height: 38px !important;
}
.mb_58 {
    margin-bottom: 58px;
}
.pl_9 {
    padding-left: 9px;
}
.line_height_25{
    line-height: 25px !important;
}
.payment_me {
    width: 130px;
    padding-right: 10px;
}
.credit_span {
    width: calc(100% - 130px);
}
.payment_me::after {
    content: ":";
    position: absolute;
    right: 4px;
}
/******* checkout css - Ends ***********/

/******* order confirmed css ***********/
.order_confirmed_page {
    padding: 58px 0;
}
.order_confirmed_page .container {
    width: 783px;
}
.thankyou_page {
    border-radius: 8px;
}
.thank_you_header {
    padding: 55px 45px 45px 45px;
}
.cart_bor_btm {
    border-bottom: 0.5px dashed var(--text_color);
}
.thank_you_body {
    padding: 35px 45px;
}
.pro_cart_det.payemnt_total_pri {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 15px;
}
.thank_btn {
    padding: 23px 45px;
}
.mr_38 {
    margin-right: 38px;
}
.mt_26 {
    margin-top: 26px;
}
.cart_pro_blk .cart_pro_img {
    width: 36px;
}
.cart_pro_blk .cart_pro_det {
    width: calc(100% - 36px);
}
.cart_pro_blk .cart_pro_img img {
    width: 36px;
}
.cart_pro_blk .cart_pro_su {
    margin: 0;
}
.cart_pro_blk .cart_pro_su:not(:last-child) {
    margin-bottom: 18px;
}
.right_blk_menu .dropdown-menu.show {
    right: 0 !important;
    left: auto !important;
    transform: inherit !important;
    top: 30px !important;
}
/******* order confirmed css - Ends ***********/

/* Hide all iframes except the one with id 'attentive_overlay' */
iframe {
    display: block ;
}

iframe#attentive_overlay,
iframe#attentive_creative {
    display: block !important;
}

/***** css changes on 31/03/2023 *********/
.pay_lk {
    margin-bottom: 20px;
}
.pay_lk.sezzle_blk .pay_header {
    border: none;
    padding: 0;
}
.pay_lk.sezzle_blk {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 14px;
    border: none;
}
.pay_lk.sezzle_blk .pay_header label {
    margin: 0 !important;
    width: 15px !important;
}
.pay_lk.sezzle_blk .pay_header label + img {
    width: calc(100% - 22px);
}
.checkbox_label.radio_label input:checked + .checbox::after {
    border: none !important;
}
.pay_lk  input:checked + .pay_header .checkbox_label.radio_label .checbox::after {
    width: 6px;
    height: 6px;
    top: 3.8px;
    left: 3.8px;
    content: "";
    position: absolute;
    transform: inherit;
    z-index: 1;
    background-color: var(--def_color);
    border-radius: 50%;
}
.pay_lk  input:checked + .pay_header + .auth_hide{
    display: block;
}
.pay_lk  input:checked + .pay_header .checkbox_label.radio_label .checbox {
    background-color: var(--text_color);
}
.pay_lk .checkbox_label.radio_label  .checbox {
    width: 15px;
    height: 15px;
}
.pay_lk.authorize_blk {
    border: none;
    padding: 0;
}
.pay_lk.authorize_blk .pay_header {
    background-color: var(--sec_color);
    border-radius: 8px;
    border: none;
    padding: 14px;
    flex-wrap: wrap;
    width: 100%;
    height: 82px;
}
.pay_lk.authorize_blk .pay_header label + * {
    width: calc(100% - 22px);
    margin-left: 6px;
    margin-top: 0;
}
.auth_hide {
    display: none;
}
.pay_lk.authorize_blk .cpay_body.form_input_blk {
    padding-left: 0;
    padding-right: 0;
}
.pay_lk.authorize_blk .pay_header label {
    width: 15px;
    margin: 0 !important;
}
.pay_lk.authorize_blk>input[type="radio"] {
    position: absolute;
    width: 100%;
    height: 82px;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
}
.pay_lk.authorize_blk .pay_header .auth_div label {
    width: auto;
    font-size: 14px;
    line-height: 17px;
}
.pay_lk.authorize_blk .pay_header .auth_div label + * {
    width: auto !important;
    margin: 0 !important;
}
.sezzle_img {
    width: auto !important;
}


@media only screen and (max-width: 767px) {
    body .pay_lk.authorize_blk .pay_header, body .pay_lk.authorize_blk>input[type="radio"] {
        width: 100%;
        height: 100px;
    }
}
/***** css changes on 31/03/2023 - Ends *********/

/***** css changes on 21/12/2023  *********/
.subtotal_blk {
    border-bottom: 1px dashed #DDDDDD; 
}
.pb_10 {
    padding-bottom: 10px;
}
.total_blk {
    border-top: 1px dashed #DDDDDD;
}
.pt_16 {
    padding-top: 16px;
}
.shipping_cost_label {
    width: calc(100% - 17px) !important;
}
.mt_1 {
    margin-top: 1px;
}

/***** css changes on 21/12/2023 - Ends  *********/

/***** css changes on 03/01/2024 Adjust Shipping On All Steps of Checkout *********/
.shipping_method_blk>div:not(:first-child) {
    border-top: 1px solid var(--pri_color);
}
.shipping_method_blk>div {
    padding: 12px;
}
.shipping_method_blk .shipping_left {
    cursor: pointer;
}
.shipping_method_blk .checbox {
    margin-bottom: 3px;
}
/***** css changes on 03/01/2024 Adjust Shipping On All Steps of Checkout Ends *********/

/***** css on 03/04/2024 Build Your Own Bundle *****/
.build_bundle {
    background-color: var(--button_color);
    border-radius: 4px;
    padding: 11px 15px;
    border: 1px solid var(--button_color);
    color: var(--text_color) !important;
    width: calc(50% - 13.5px);
    margin-left: 13.5px;
    text-align: center;
}
.pro_head_blk {
    width: 47%;
}

.input_bundle_btn {
    width: 53%;
}
.Bundle_Added {
    background-color: var(--def_color);
}
/*** css Changes on 05/04/2024 ***/
.custom_bunde_left {
    padding: 45px 30px;
}
.font_10 {
    font-size: 10px !important;
    line-height: 12px !important;
}
.bundle_progress_bar ul li {
    width: 16.667%;
    position: relative;
}
.bundle_progress_bar ul li .round_circle {
    width: 32px;
    height: 32px;
    background-color: var(--def_color);
    border: 1px solid #E6E5E8;
    border-radius: 50%;
    color: var(--pri_color)80;
    position: relative;
    z-index: 2;
}
.bundle_progress_bar ul li:not(:first-child)::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 13px;
    background-color: var(--sec_color);
    top: 9.5px;
    z-index: 1;
    right: 50%;
}
.text_opacity {
    color: var(--pri_color)80;   
}
.product_upload {
    position: relative;
    background-color: var(--sec_color);
    padding: 14px;
    border-radius: 8px;
}
.product_upload input[type="file"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
}
.shop_more.add_to_bundle.add_bundle_btn {
    padding: 9.5px;
    width: 449px;
}
.shop_more.add_to_bundle.add_bundle_btn.disabled {
    opacity: 45%;
    background-color: var(--pri_color);
    color: var(--def_color) !important;
}
.w-90 {
    width: 90% !important;
}
.aff_bundle_blk {
    width: 70%;
}
.aff_bundle_blk .product_search_input {
    width: calc(80% - 200px);
}
.aff_bundle_blk .product_search_input.form_input_blk .form-control {
    height: 42px;
}
.aff_bundle_blk .product_search_input .search_icon {
    width: 42px;
    height: 42px;
}
.aff_cust_bun_left {
    background-color: var(--sec_color);
}
.cus_pro_order_blk .order_pro_img, .order_pro_img img {
    width: 92px;
}
.cus_pro_order_blk .order_pro_det {
    width: calc(100% - 92px);
}
.cus_pro_order_blk .cart_quantity.quan_cart_blk .refer_product_input_group,
.cus_pro_order_blk .quan_cart_blk .refer_product_input_group .input-group-prepend button {
    height: 42px;
}
.cus_pro_order_blk .subscri_blk.choose_fla_blk {
    width: 335px;
}
.cus_pro_order_blk .subscri_blk {
    width: calc(100% - 155px);
}
.warning_c_alert_wrapper.alert.alert-danger.warning_wrapper_show {
    left: 50%;
    min-width: 550px;
    position: absolute;
    z-index: 2;
    width: auto;
    transform: translateX(-50%);
}
.text_green {
    color: green !important;
}
.mr_30 {
    margin-right: 30px !important;
}
.cart_accordation {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 20px;
}
.card_head_left img {
    width: 88px;
}
.card_head_left .content {
    width: calc(100% - 88px);
}
.card_head_left {
    width: 60%;
}
.card_head_rgt {
    width: 40%;
}
iframe {
    display: none;
}
.buy_now.modify_btn {
    padding: 4px 8px;
    border: 1px solid var(--pri_color);
}
.pr_6 {
    padding-right: 6px !important;
}
.segregation {
    width: 1px;
    height: 11px;
    background-color: #C5C2CB;
}
.cart_accordation .card_body {
    padding-top: 40px;
}
.cart_accordation .cart_pro_list {
    background-color: var(--def_color);
    margin-bottom: 0;
}
.card_body_cart_list>*:not(:last-child) {
    margin-bottom: 12px !important;
}
.img_rotate {
    transform: rotate(180deg);
}
.prod_added .round_circle {
    background-color: var(--pri_color) !important;
    color: var(--def_color) !important;
}
.bundle_progress_bar ul .prod_added:not(:first-child)::before {
    background: var(--pri_color);
}
.bundle_progress_bar ul li.highlight_last .round_circle {
    box-shadow: 0px 4px 4px 0px #00000040;
    transform: scale(1.27);
}
.bundle_modify_button {
    width: max-content;
    border: 1px solid var(--pri_color);
    background: var(--def_color);
    color: var(--pri_color);
}
span.d-block.w-50.font_16.text_black.text-right.let_spa_2.fw_ar_bold.custom {
    padding-inline-end: 0;
    padding-bottom: 14px;
    width: 100% !important;
    text-align: left !important;
}
/*** css Changes on 05/04/2024 Ends ***/

.join_newsletter_blk form.needsclick.klaviyo-form {
    padding: 0 !important;
}
.join_newsletter_blk form.needsclick.klaviyo-form > div > div > div:first-child {
    border-radius: 6px !important;
    padding: 0 0px 0 0 !important;
    width: 224px;
    flex: inherit !important;
    margin-right: 12px;
    background-color: transparent !important;
}
.join_newsletter_blk form.needsclick.klaviyo-form>div>div>div:last-child {
    padding: 0 0px 0 0 !important;
    width: 97px;
    flex: inherit !important;
}
.join_newsletter_blk form.needsclick.klaviyo-form>div>div>div:last-child button {
    border: none !important;
    padding: 5px !important;
    border-radius: 8px !important;
    background-repeat: no-repeat !important;
    background-color: var(--def_color) !important;
    box-shadow: 0px 1px 2px 0px #0000000D;
    color: var(--text_color);
    height: 38px !important;
    font-family: "Barlow Semi Condensed", sans-serif !important;
    font-weight: 700 !important;
}
.white_text {
    color: var(--def_color);
}
.montserrat{
    font-family: 'Montserrat', sans-serif !important;
}
.plus{
    margin-left: -3px;
}

input.cart_add_product_btn.added {
    background-color: var(--pri_color) !important;
    color: var(--def_color);
}

/** banner section ***/
.refer_banner_slide.public_banner_slide .carousel-item {
    padding: 104px 53px;
    background-image: url('../images/public_images/new_banner_image.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-color: var(--text_color);
}
/** banner section Ends ***/
.text_red{
    color: #ca4d4d;
    margin-top: 9px;
}

/***** css changes on 10/01/2025 *********/
.quiz_blk {
    min-height: calc(100vh - 330px);
    background-color: var(--def_color);
    padding: 40px 40px 85px 40px;
    border-radius: 4px;
    background-image: url('../images/public_images/quiz_background.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
}
.questinaries_con_blk {
    background-color: var(--sec_color);
}
.font_44 {
    font-size: 44px;
    line-height: 68px;
}
.ml_27 {
    margin-left: 27px;
}
.mr_27 {
    margin-right: 27px;
}
.quiz_options {
    border: 1px solid #F3F3F3;
    box-shadow: 0px 4px 4px 0px #402E7740;
    border-radius: 20px;
    width: 235px;
    height: 243px;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    outline: none !important;
}
.ml_3 {
    margin-left: 3px;
}
.next_button.buy_now {
    min-width: 235px;
    border-radius: 80px;
    height: 80px;
    outline: none !important;
}
.quiz_options.selected, .ques_pro_each.selected {
    background-color: var(--button_color);
    color: var(--def_color);
    border-color: var(--button_color);
}
.quiz_options .inactive_icon, .quiz_options.selected .active_icon  {
    display: none;
}
.quiz_options.selected .inactive_icon {
    display: block;
}
.first_quiz_button {
    width: 595px;
}
.gender_quiz_button {
    width: 515px;
}
.quiz_options.sec_quiz_options {
    width: 282px;
    height: 80px;
    border-radius: 75px;
}
.quiz_bundle .shop_more.add_to_bundle {
    width: fit-content;
}
.quiz_options.sec_quiz_options:not(:last-child) {
    margin-right: 27px;
}
.letter_spacing_2per {
    letter-spacing: 0.02em;
}
.second_options_blk {
    min-height: 200px;
}
.back_button {
    background: none;
    border: none;
    padding: 0;
    outline: none !important;
}
.back_button .text_pri {
    color: #402E77;
}
.ques_pro_each {
    background-color: #C8C3D9;
    height: 7px;
    border-radius: 3.5px;
}
.quiz_pro_found {
    border: 1px solid #402E77;
    background: #F5F1FF;
    border-radius: 27px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 3.5px 18px;
}
/***** css changes on 10/01/2025 Ends *********/
.next_button.buy_now[disabled] {
    opacity: 0.7;
}
.select_flavour.select_flavour_bundle {
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    padding: 5px 25px 5px 10px !important;
    height: 29px;
    background-position: calc(100% - 10px);
    font-size: 13px;
    line-height: 15px;
}
button.shop_more.add_cart_btn.font_16.banner_text.text-center.fw_ar_reg.add_to_bundle.add_bundle_btn.proceed {
    margin-right: 408px;
}
.font_13 {
    font-size: 13px !important;
    line-height: 15px !important;
}
.custom_bundle_qty.quan_cart_blk .refer_product_input_group {
    border-color: #fff;
}
.cart_quantity.quan_cart_blk .refer_product_input_group {
    border-radius: 5px;
    height: 29px;
    width: 100%;
}
.quiz_bundle .shop_more.add_to_bundle {
    width: fit-content;
}
.select_flavour.select_flavour_bundle {
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    padding: 5px 25px 5px 10px !important;
    height: 29px;
    background-position: calc(100% - 10px);
    font-size: 13px;
    line-height: 15px;
}
/* css changes on 27/01/2025 */
/* button.shop_more.add_cart_btn.font_18.banner_text.text-center.fw_ar_reg.add_to_bundle.add_bundle_btn.proceed {
    margin-right: 380px;
    width: 304px;
    height: 44px;
} */

.next_button {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .next_button_op {
    background-color: var(--button_color);
    border-radius: 8px;
    border: none;
    padding: 17px 26.5px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    opacity: 0.6;
}
  .next_button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--button_color);
    width: 0%; 
    transition: width 0.1s ease-out;
    z-index: 2;
    pointer-events: none;
  }
  .fade-to-dark-purple.buy_now {
    position: relative;
    z-index: 1;
    background-color: rgb(47 7 95 / 70%);
  }
  .fade-to-dark-purple.buy_now span, .fade-to-dark-purple.buy_now img {
    position: relative;
    z-index: 3;
  }
  .fade-to-dark-purple::before {
    animation: moveColorProgress 5s linear forwards;
  }
  
  @keyframes moveColorProgress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
    .mb_5 {
        margin-bottom: 5px !important;
    }
    .cart_quantity.quan_cart_blk.qty_cus_bun {
        width: 88px;
    }
    button.shop_more.add_cart_btn.font_18.banner_text.text-center.fw_ar_reg.add_to_bundle.add_bundle_btn.proceed_1 {
        margin-right: 2px;
        margin-bottom: 25px;
        width: 304px;
    }
    .modal-body1.montserrat {
        padding: 21px;
    }
/* css changes on 27/01/2025 ends*/
/* css changes on 12/02/2025*/
.quiz_bundle_btn {
    position: fixed;
    bottom: 25px;
    right: 0;
    z-index: 3;
}
.quiz_bundle_btn .shop_more {
    margin: 0 auto;
}

.quiz_bundle_btn.remove-fixed {
    display: none !important;
}
.hide_button.hide_blk {
    display: none;
}
.hide_button {
    display: flex;
}
/* css changes on 12/02/2025 ends*/
  
